.print-banner {
    display: block;
}

.cookies-top-bloc, .menu-skip, .header, .menu-main, .menu-stratis, .top-of-content, .go-to-top, .menu-cross, .newsletter, .stratis, .section-main__aside, .filters {
    display: none !important;
}

.wrapper-940 {
    width: 100%;
    margin: 0;
    padding: 0;
}

.ddm__sub-level {
    display: block !important;
    position: static !important;
    top: inherit !important;
    right: inherit !important;
    bottom: inherit !important;
    left: inherit !important;
    height: auto !important;
    max-height: 100% !important;
}

.list {
    margin-top: 3em;
}

.heading {
    .heading__wrapper-date {
        .date-1 {
            &::before {
                display: none;
            }
        }
    }
}
